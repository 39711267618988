import { USER_LOGIN_REQUEST, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_MEMBER_UPDATE, GET_PROJECTS_RESET } from "./Types"
import { userService } from "../../_services/auth.service"

//page speed
export const login = (payload) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: USER_LOGIN_REQUEST, payload: [] })
  await userService.userLogin(payload).then(
    (data) => {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data.results })
    },
    (error) => {
      dispatch({ type: USER_LOGIN_FAILURE, payload: error })
    }
  )
}
export const userLogout = () => async (dispatch) => {
  localStorage.clear()
  dispatch({ type: GET_PROJECTS_RESET })
  dispatch({ type: USER_LOGOUT })
}

export const userMemberUpdate = (payload) => async (dispatch) => {
  let user = JSON.parse(localStorage.getItem("user"))
  user.type = payload
  localStorage.setItem("user", JSON.stringify(user))
  dispatch({ type: USER_MEMBER_UPDATE, payload })
}
