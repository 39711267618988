const typography = {
  useNextVariants: true,
  fontFamily: ["Nunito", "sans-serif"].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
  h1: {
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 14,
  },
  button: {
    textTransform: "none",
  },
}

export default typography
