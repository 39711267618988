import React from "react"
import { Dialog, DialogTitle, IconButton, Slide, DialogContent } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const DialogComponent = ({ closeModel, openModel, title, Content, fullWidth, maxWidth }) => {
  return (
    <Dialog scroll={"body"} fullWidth maxWidth={maxWidth ? maxWidth : "sm"} onClose={closeModel} aria-labelledby="simple-dialog-title" open={openModel} TransitionComponent={Transition}>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "15px" }}>
        {typeof title === "function" ? title() : title}
        <IconButton style={{ alignSelf: "flex-start" }} edge="start" color="inherit" onClick={closeModel} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* </Box> */}
      <DialogContent dividers={true}>
        <Content />
      </DialogContent>
    </Dialog>
  )
}

export default DialogComponent
