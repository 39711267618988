import { IS_DASHBOARD_NEED_REFRESH, GET_MONTHLYREVENUE_SUCCESS, GET_MONTHLYREVENUE_REQUEST, GET_MONTHLYREVENUE_FAILURE, GET_DASHBOARDKEYWORDS_SUCCESS, GET_DASHBOARDKEYWORDS_REQUEST, GET_DASHBOARDKEYWORDS_FAILURE, GET_DASHBOARDLIGHTHOUSE_SUCCESS, GET_DASHBOARDLIGHTHOUSE_REQUEST, GET_DASHBOARDLIGHTHOUSE_FAILURE, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE, GET_DASHBOARD_RANK_DISTBU_REQUEST, GET_DASHBOARD_RANK_DISTBU_SUCCESS, GET_DASHBOARD_RANK_DISTBU_FAILURE, GET_PAGE_SPEED_REQUEST, GET_PAGE_SPEED_SUCCESS, GET_PAGE_SPEED_FAILURE, GET_ASSIGNED_PAGES_REQUEST, GET_ASSIGNED_PAGES_SUCCESS, GET_ASSIGNED_PAGES_FAILURE, GET_DASHBOARD_ACTIONS_REQUEST, GET_DASHBOARD_ACTIONS_SUCCESS, GET_DASHBOARD_ACTIONS_FAILURE, UPDATE_TRACKED_PAGES } from "../actions/Types"

const INIT_STATE = {
  monthlyRevenue: null,
  monthlyRevenueloading: false,
  error: null,
  dashboardkeywords: null,
  dashboardkeywordsLoading: false,
  dashboardLighthouse: null,
  dashboardLighthouseLoading: false,
  avaragePageScore: null,
  dashboardAavaragePageScoreLoading: false,
  dashboardRankDistributionData: null,
  dashboardrankdistributionLoading: false,
  pageSpeedData: null,
  pageSpeedLoading: false,
  assigendPagesData: null,
  assigendPagesLoading: false,
  ActionsLoading: false,
  dasboardActions: null,
  isDashboardNeedsRefresh: false,
}
const dashBoardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MONTHLYREVENUE_REQUEST:
      return { ...state, monthlyRevenueloading: true }
    case GET_MONTHLYREVENUE_SUCCESS:
      return { ...state, monthlyRevenueloading: false, monthlyRevenue: action.payload }
    case GET_MONTHLYREVENUE_FAILURE:
      return { ...state, monthlyRevenueloading: false, error: action.payload }

    //dashboard keywords
    case GET_DASHBOARDKEYWORDS_REQUEST:
      return { ...state, dashboardkeywordsLoading: true }
    case GET_DASHBOARDKEYWORDS_SUCCESS:
      return { ...state, dashboardkeywordsLoading: false, isDashboardNeedsRefresh: false, dashboardkeywords: action.payload }
    case GET_DASHBOARDKEYWORDS_FAILURE:
      return { ...state, dashboardkeywordsLoading: false, isDashboardNeedsRefresh: false, error: action.payload }

    //dashboard avarage page score
    case GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST:
      return { ...state, dashboardAavaragePageScoreLoading: true }
    case GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS:
      return { ...state, dashboardAavaragePageScoreLoading: false, avaragePageScore: action.payload }
    case GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE:
      return { ...state, dashboardAavaragePageScoreLoading: false, error: action.payload }

    // dashboard lighthouse
    case GET_DASHBOARDLIGHTHOUSE_REQUEST:
      return { ...state, dashboardLighthouseLoading: true }
    case GET_DASHBOARDLIGHTHOUSE_SUCCESS:
      return { ...state, dashboardLighthouseLoading: false, dashboardLighthouse: action.payload }
    case GET_DASHBOARDLIGHTHOUSE_FAILURE:
      return { ...state, dashboardLighthouseLoading: false, error: action.payload }

    //dashboard rank distribution
    case GET_DASHBOARD_RANK_DISTBU_REQUEST:
      return { ...state, dashboardrankdistributionLoading: true }
    case GET_DASHBOARD_RANK_DISTBU_SUCCESS:
      return { ...state, dashboardrankdistributionLoading: false, dashboardRankDistributionData: action.payload }
    case GET_DASHBOARD_RANK_DISTBU_FAILURE:
      return { ...state, dashboardrankdistributionLoading: false, error: action.payload }

    //dashboard page speed
    case GET_PAGE_SPEED_REQUEST:
      return { ...state, pageSpeedLoading: true, pageSpeedData: null }
    case GET_PAGE_SPEED_SUCCESS:
      return { ...state, pageSpeedLoading: false, pageSpeedData: action.payload }
    case GET_PAGE_SPEED_FAILURE:
      return { ...state, pageSpeedLoading: false, error: action.payload, pageSpeedData: null }

    //dashboard assigned pages
    case GET_ASSIGNED_PAGES_REQUEST:
      return { ...state, assigendPagesLoading: true }
    case GET_ASSIGNED_PAGES_SUCCESS:
      return { ...state, assigendPagesLoading: false, assigendPagesData: action.payload }
    case GET_ASSIGNED_PAGES_FAILURE:
      return { ...state, assigendPagesLoading: false, error: action.payload }

    //dashboard actions
    case GET_DASHBOARD_ACTIONS_REQUEST:
      return { ...state, ActionsLoading: true }
    case GET_DASHBOARD_ACTIONS_SUCCESS:
      return { ...state, ActionsLoading: false, dasboardActions: action.payload.results }
    case GET_DASHBOARD_ACTIONS_FAILURE:
      return { ...state, ActionsLoading: false, error: action.payload }

    case IS_DASHBOARD_NEED_REFRESH:
      return { ...state, isDashboardNeedsRefresh: action.payload }

    case UPDATE_TRACKED_PAGES: {
      return { ...state, dashboardkeywords: state.dashboardkeywords ? { ...state.dashboardkeywords, trackedpages: action.payload } : null }
    }
    default:
      return { ...state }
  }
}

export default dashBoardReducer
