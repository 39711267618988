import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { userService } from "../../_services/auth.service"
import { useHistory, Link, useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"
import AuthLayout from "../../layouts/Auth"
import { Button as MuiButton, Paper, Typography, TextField, Box, Alert } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { spacing } from "@mui/system"
import logo from "../../assets/images/bd-logo.png"
import { makeStyles } from "@mui/styles"

import "./index.css"
const useStyles = makeStyles(() => ({
  red: {
    color: "red",
  },
  redCheckBox: {
    color: "red",
    outline: "1px solid red",
    outlineOffset: "-2px",
  },
}))

const Button = styled(MuiButton)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function SignUp() {
  const history = useHistory()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, errors, watch, reset } = useForm({
    mode: "all",
  })

  const [loading, setLoading] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [signUpEmail, setSignUpEmail] = useState(null)
  const [token, setToken] = useState(null)
  const { search } = useLocation()
  const hasToken = search.indexOf("?token=") > -1
  useEffect(() => {
    if (hasToken) {
      const tokenFound = search.split("=")[1]
      getUser(tokenFound)
      setToken(tokenFound)
    }
  }, [])

  const getUser = async (token) => {
    console.log(token)
  }
  const onSubmit = async (data) => {
    setLoading(true)
    await userService
      .signup({ ...data, token })
      .then((res) => {
        // enqueueSnackbar(res.message, {
        //   variant: "success",
        // })
        if (token) {
          enqueueSnackbar(res.message, {
            variant: "success",
          })

          history.push("/auth/sign-in")
        }
        setLoading(false)

        setIsSignedUp(true)
        setSignUpEmail(res.results.email)
        timedAlertSignUp()
        reset()
      })
      .catch((err) => {
        if (err) {
          enqueueSnackbar(err, {
            variant: "err",
          })
          reset()
          setLoading(false)
        }
      })
  }
  const timedAlertSignUp = () => {
    setTimeout(() => setIsSignedUp(false), 10000)
  }
  const changeValue = (e, type) => {
    const value = e.target.value
    const nextState = {}
    nextState[type] = value
  }
  const style = {
    layout_padding: {
      padding: "20px",
    },
  }
  const password = useRef({})
  password.current = watch("password", "")
  return (
    <AuthLayout style={style.layout_padding}>
      <Wrapper className="wrapper_width_signup">
        {/* <Helmet title="Sign Up" /> */}
        {/* <Typography component="h1" align="center">
          <img className="img-fluid" alt="Biscuit Data" src={require("../../assets/images/logo-site.png")} width="150" />
        </Typography> */}
        <Box className="biscuit_logo">
          <img src={logo} alt="logo" width="180" />
        </Box>
        <Typography className="signup_title" align="center" variant="h6">
          {/* SignUp to Biscuit Data */}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="row pl-2 pr-2">
            <Box display="flex" className="col-md-12 col-12 mb-2">
              <Box width="100%">
                <TextField variant="standard" fullWidth name="firstname" type="text" inputRef={register({ required: "Required" })} label="First Name" margin="normal" className="mt-2" error={!!errors.firstname} helperText={errors.firstname ? errors.firstname.message : ""} onChange={(e) => changeValue(e, "firstname")} />
              </Box>
              <Box ml={2} width="100%">
                <TextField variant="standard" fullWidth name="lastname" type="text" inputRef={register({ required: "Required" })} label="Last Name" margin="normal" className="mt-2" error={!!errors.lastname} helperText={errors.lastname ? errors.lastname.message : ""} onChange={(e) => changeValue(e, "lastname")} />
              </Box>
            </Box>
            {!token && (
              <>
                <TextField fullWidth variant="standard" name="organization" type="text" inputRef={register({ required: "required" })} label="Organization" margin="normal" className="mt-2" error={!!errors.organization} helperText={errors.organization ? errors.organization.message : ""} onChange={(e) => changeValue(e, "organization")} />
                <TextField
                  variant="standard"
                  name="email"
                  type="email"
                  inputRef={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  label="Email"
                  margin="normal"
                  className="mt-2"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  fullWidth
                  onChange={(e) => changeValue(e, "email")}
                />
              </>
            )}

            <TextField
              variant="standard"
              fullWidth
              name="password"
              type="password"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                  message: "Invalid Password need at least a number, and at least a special character(Eg: @,$,!,%,*,?, &) and min 6 characters",
                },
              })}
              label="Password"
              margin="normal"
              className="mt-2"
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
              onChange={(e) => changeValue(e, "password")}
            />

            <TextField
              variant="standard"
              fullWidth
              margin="normal"
              className="mt-2"
              label="Confirm Password"
              name="password_repeat"
              type="password"
              error={!!errors.password_repeat}
              inputRef={register({
                validate: (value) => value === password.current || "The passwords do not match",
              })}
              helperText={errors.password_repeat && errors.password_repeat.message}
            />
          </div>
          <Box p={2}>
            <label>
              <input className={errors.terms && classes.redCheckBox} type="checkbox" name="terms" ref={register({ required: "Required" })} />
              <span className={errors.terms && classes.red}>
                I agree with the
                <span className="privacy_policy"> Privacy Policy</span>and&nbsp;
                <span className="privacy_policy">Terms of Use</span>
              </span>
            </label>
          </Box>
          <div className="text-center pt-2">
            <LoadingButton fullWidth type="submit" loading={loading} loadingIndicator="Signing Up..." variant="contained">
              Sign Up
            </LoadingButton>
          </div>
        </form>
        {isSignedUp && (
          <Box marginTop={"10px"}>
            <Alert severity="success">
              Signup successfull please login to your email: <b>{signUpEmail}</b> to activate
            </Alert>
          </Box>
        )}
        <Box pt={4} textAlign="center">
          <Box>
            Already have account ?
            <Link className="signin_color_for_aTag" disabled={loading} to="/auth/sign-in">
              Log In
            </Link>
          </Box>
        </Box>
      </Wrapper>
    </AuthLayout>
  )
}

export default SignUp
