import React, { useEffect } from "react"
import styled from "styled-components"
import { useHistory, Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { login } from "../../redux/actions/userActions"
import { useSnackbar } from "notistack"
import AuthLayout from "../../layouts/Auth"

import { Box, Paper, Typography, TextField, Checkbox } from "@mui/material"
import logo from "../../assets/images/bd-logo.png"

import "./index.css"
import { useDispatch, useSelector } from "react-redux"
import { LoadingButton } from "@mui/lab"

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`
function SignIn() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm({ mode: "all" })
  const { user, loading, error } = state.userReducer

  // // consclgt { isSubmitting } = formState
  useEffect(() => {
    if (user) {
      history.push("/")
    }
  }, [user])
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
      })
    }
  }, [error])
  const onSubmit = async (data) => {
    dispatch(login(data))

    // await userService
    //   .login(data)
    //   .then((res) => {
    //     enqueueSnackbar(res.message, {
    //       variant: "success",
    //     })
    //     history.push("/")
    //   })
    //   .catch((err) => {
    //     enqueueSnackbar(err, {
    //       variant: "error",
    //     })
    //     setDisable(false)
    //   })
  }

  return (
    <AuthLayout>
      <Wrapper className="main_sinin">
        <Box className="biscuit_logo">
          <img src={logo} alt="logo" width="180" />
        </Box>
        <Box textAlign="center">
          <Typography variant="h6" className="text-center">
            Sign in to your account to continue
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="row pl-2 pr-2">
            <div className="col-md-12 col-12 mb-2">
              <TextField variant="standard" name="email" type="email" inputRef={register({ required: "required" })} label="Email" margin="normal" className="mt-2" error={!!errors.email} helperText={errors.email ? errors.email.message : ""} fullWidth />
            </div>
            <div>
              <TextField variant="standard" name="password" type="password" inputRef={register({ required: "required" })} label="Password" margin="normal" className="mt-2" error={!!errors.password} helperText={errors.password ? errors.password.message : ""} fullWidth />
            </div>
            <div>
              <Checkbox className="remember_box" />
              <span>Remember me</span>
            </div>
          </div>
          <div className="text-center pt-2">
            <LoadingButton fullWidth type="submit" loading={loading} loadingIndicator="logging in..." variant="contained">
              Login
            </LoadingButton>
          </div>
        </form>
        <Box pt={4}>
          <Link className="forgot_password" to="/auth/forgot-password">
            Forgotten password?
          </Link>
        </Box>
        <Box pt={4}>
          Not got an account?
          <Link className="sign_up_link" to="/auth/sign-up">
            Signup
          </Link>
        </Box>
      </Wrapper>
    </AuthLayout>
  )
}

export default SignIn
