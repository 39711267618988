import React from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import LayersIcon from "@mui/icons-material/Layers"
import AdjustIcon from "@mui/icons-material/Adjust"
import TuneIcon from "@mui/icons-material/Tune"
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import ViewModuleIcon from "@mui/icons-material/ViewModule"
import async from "../components/Async"

import * as Icon from "react-feather" //preview available at https://feathericons.com/
// import {
//   // PieChart,
//   // BarChart2,
//   CheckSquare,
//   List,
//   ArrowRight,
//   Archive,
//   User,
//   Users,
// } from "react-feather"
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"))
const SignUp = async(() => import("../pages/auth/SignUp"))
const ForgotPassword = async(() => import("../pages/auth/ForgotPassword"))
const ResetPassword = async(() => import("../pages/auth/ResetPassword"))
const Page404 = async(() => import("../pages/auth/Page404"))
const Page500 = async(() => import("../pages/auth/Page500"))
//user profile
const UserProfile = async(() => import("../pages/userProfile/index.js"))
//DAshboard
const Dashboard = async(() => import("../pages/dashboards/indexNew"))
//user management
const user_mangement = async(() => import("../pages/user_mangement/UserManagement"))
// footer links
const Support = async(() => import("../pages/FooterLinks/Support"))
const HelpCenter = async(() => import("../pages/FooterLinks/HelpCenter"))
const Privacy = async(() => import("../pages/FooterLinks/Privacy"))
const TermsofService = async(() => import("../pages/FooterLinks/TermsofService"))
// members
const Members = async(() => import("../pages/members/members.js"))

// content components
const editContent = async(() => import("../pages/content_creation/ContentEditor"))
// const Competition = async(() => import("../pages/competitions/index"))
const Pages = async(() => import("../pages/pages/Pages"))
const SinglePage = async(() => import("../pages/pages/SinglePage"))

// settings
const Settings = async(() => import("../pages/settings/Settings"))
const OnBoarding = async(() => import("../pages/settings/AutoSuggested"))
const MetaTags = async(() => import("../pages/settings/MetaTags"))
const GoogleLightHouse = async(() => import("../pages/settings/GoogleLightHouse"))
// const DataSourceSettings = async(() => import("../pages/settings/DataSource"))
const SERP = async(() => import("../pages/settings/Serp"))
const ContentManagement = async(() => import("../pages/settings/ContentManagement"))

//PROJECTS
const CreateProject = async(() => import("../pages/projects/MasterCreateProject"))
// const Projects = async(() => import("../pages/projects/Projects"))
const AllProjects = async(() => import("../pages/projects/AllProjects"))
const EditProject = async(() => import("../pages/projects/EditProject"))
const AdwordsSuccess = async(() => import("../pages/projects/adwordsSuccess"))
const updateSitemap = async(() => import("../pages/update_sitemap/UpdateSitemap"))

// back-links
// const BackLinks = async(() => import("../pages/backlinks/index.js"));

// Single Keyword Status
const SingleKeywordStatus = async(() => import("../pages/single_keyword_status/index"))

// REPORTS
// const Reports = async(() => import("../pages/reports/index"));
// TO DO
const ToDo = async(() => import("../pages/todo/TodoList"))
// KEYWORDS
const Keywords = async(() => import("../pages/keywords/KeywordsMain"))

//My Accounts
const MyAccounts = async(() => import("../pages/myAccounts/index.js"))
//DataSource
const DataSource = async(() => import("../pages/projects/createProjectComponents/DataSource"))
const AdwordsToken = async(() => import("../globalComponent/AdwordsToken"))
const CronJobsLogs = async(() => import("../pages/cronJobsLogs/index"))
const projectsRoutes = {
  id: "Projects Settings", //here is where the label is
  path: "/projects-settings",
  name: "Projects Settings",
  icon: <Icon.Settings />,
  component: AllProjects,
  children: [
    {
      path: "/create-project",
      name: "Create Project",
      component: CreateProject,
    },
    // /projects/create-project/:id
    {
      path: "/project/:id/update-sitemap",
      name: "Update Sitemap",
      component: updateSitemap,
    },
    {
      path: "/adwords/success",
      name: "Adwords Success",
      component: AdwordsSuccess,
    },
  ],
}

// const projectsRoutes = {
//   id: "Projects Settings", //here is where the label is
//   path: "/projects-settings",
//   name: "Projects Settings",
//   icon: <Icon.Settings />,
//   component: AllProjects,
//   children: null,
// }

const AdwordsRoute = {
  id: "AdwordsToken",
  path: "/connect-adwords",
  component: AdwordsToken,
}
const dashboardsRoutes = {
  id: "Dashboard",
  path: "/project/:id/dashboard",
  icon: <DashboardIcon />,
  containsHome: true,
  children: null,
  component: Dashboard,
}
const membersRoutes = {
  id: "members",
  path: "/members",
  icon: <DashboardIcon />,
  containsHome: true,
  children: null,
  component: Members,
}
const supprtRoutes = {
  id: "support",
  path: "/project/:id/support",
  icon: <DashboardIcon />,
  children: null,
  component: Support,
}

const helpCenterRoutes = {
  id: "support",
  path: "/project/:id/help-center",
  icon: <DashboardIcon />,
  children: null,
  component: HelpCenter,
}

const privacyRoutes = {
  id: "privacy",
  path: "/project/:id/privacy",
  icon: <DashboardIcon />,
  children: null,
  component: Privacy,
}

const termsRoutes = {
  id: "privacy",
  path: "/project/:id/termsofservice",
  icon: <DashboardIcon />,
  children: null,
  component: TermsofService,
}

const SettingsRoutes = {
  id: "Settings",
  path: "/settings",
  name: "Settings",
  icon: <TuneIcon />,
  component: Settings,
  children: [
    {
      id: "metatags",
      path: "/project/:id/settings/meta-tags",
      name: "Meta Tags",
      icon: <Icon.ArrowRight />,
      component: MetaTags,
    },
    {
      id: "GoogleLightHouse",
      path: "/project/:id/settings/Google-lighthouse",
      name: "GoogleLighthouse",
      icon: <Icon.ArrowRight />,
      component: GoogleLightHouse,
    },
    {
      id: "serp",
      path: "/project/:id/settings/serp",
      name: "SERP",
      icon: <Icon.ArrowRight />,
      component: SERP,
    },
    {
      id: "contentmanagement",
      path: "/project/:id/settings/content-management",
      name: "Content Management",
      icon: <Icon.ArrowRight />,
      component: ContentManagement,
    },
    {
      id: "AutoSuggested",
      path: "/project/:id/settings/auto-assignment",
      name: "Auto Assignment",
      icon: <Icon.ArrowRight />,
      component: OnBoarding,
    },
  ],
}

const editContentRoute = {
  id: "uesrpropfile",
  path: "/project/:id/edit-content",
  name: "edit content",
  icon: <Icon.List />,
  component: editContent,
  children: null,
}

const userProfileRoutes = {
  id: "uesrpropfile",
  path: "/user_profile",
  name: "user profile",
  icon: <Icon.List />,
  component: UserProfile,
  children: null,
}

const todoRoutes = {
  id: "To do Notes",
  path: "/project/:id/todo",
  name: "To do Notes",
  icon: <Icon.CheckSquare />,
  component: ToDo,
  children: null,
}

const MyAccountsRoutes = {
  id: "My Projects", //here is where the label is
  path: "/",
  name: "My Projects",
  icon: <Icon.Folder />,
  component: MyAccounts,
  children: null,
}

// const competitionRoute = {
//   id: "Competition",
//   path: "/project/:id/Competition",
//   icon: <BarChart2 />,
//   component: Competition,
//   children: null,
// }

const pagesRoutes = {
  id: "Pages",
  path: "/project/:id/pages",
  icon: <LayersIcon />,
  children: null,
  component: Pages,
}
const pagesRoutesWithActions = {
  id: "Pages",
  path: "/project/:id/pages?action=id",
  icon: <LayersIcon />,
  children: null,
  component: Pages,
}
const editProjectRoutes = {
  id: "EditProjects",
  path: "/project/:id/edit-project",
  badge: "8",
  component: EditProject,
  children: null,
}

const dataSourceRoutes = {
  id: "Data Sources",
  icon: <ViewModuleIcon />,
  children: null,
  path: "/project/:id/datasource",
  name: "Data Sources",
  component: DataSource,
}

// const ReportsRoutes = {
//   id: "Reports",
//   icon: <PieChart />,
//   children: null,
//   path: "/project/:id/reports",
//   component: Reports,
// };

// const backlinksRoutes = {
//   id: "backlinks",
//   icon: <AttachFileIcon />,
//   children: null,
//   path: "/project/:id/back-links",
//   component: BackLinks,
// };

const KeywordsRoutes = {
  id: "Keywords",
  icon: <AdjustIcon />,
  children: null,
  path: "/project/:id/keywords",
  component: Keywords,
}
const CronJobsLogsRoutes = {
  id: "CronJobsLogs",
  icon: <AdjustIcon />,
  children: null,
  path: "/logs",
  component: CronJobsLogs,
}
const KeywordsRoutesWithAction = {
  id: "Keywords",
  icon: <AdjustIcon />,
  children: null,
  path: "/project/:id/keywords?tab=:id&action=:id",
  component: Keywords,
}
const KeywordsRoutesWithTab = {
  id: "Keywords",
  icon: <AdjustIcon />,
  children: null,
  path: "/project/:id/keywords?tab=:id",
  component: Keywords,
}

const SinglePageRoute = {
  id: "SinglePage",
  path: "/project/:id/page/:id",
  badge: "8",
  component: SinglePage,
  children: null,
}
const user_mangementRoute = {
  id: "keyword_tracking",
  path: "/project/:id/user_mangement",
  component: user_mangement,
  children: null,
}
// user_mangement

const keyword_tracking = {
  id: "keyword_tracking",
  path: "/project/:id/keywords/:id",
  component: SingleKeywordStatus,
  children: null,
}

const keyword_tracking_with_action = {
  id: "keyword_tracking",
  path: "/project/:id/keywords/:id?tab=:id&action=:id",
  component: SingleKeywordStatus,
  children: null,
}

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Icon.Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
}

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes]

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  KeywordsRoutesWithAction,
  KeywordsRoutesWithTab,
  termsRoutes,
  privacyRoutes,
  helpCenterRoutes,
  supprtRoutes,
  projectsRoutes,
  membersRoutes,
  // backlinksRoutes,
  user_mangementRoute,
  dashboardsRoutes,
  editProjectRoutes,
  pagesRoutes,
  CronJobsLogsRoutes,
  // competitionRoute,
  dataSourceRoutes,
  // ReportsRoutes,
  todoRoutes,
  keyword_tracking,
  keyword_tracking_with_action,
  KeywordsRoutes,
  SettingsRoutes,
  editContentRoute,
  MyAccountsRoutes,
  SinglePageRoute,
  userProfileRoutes,
  pagesRoutesWithActions,
  AdwordsRoute,
]

// Routes visible in the sidebar
export const sidebarRoutes = [
  MyAccountsRoutes,
  dashboardsRoutes,
  pagesRoutes,
  KeywordsRoutes,

  // competitionRoute,
  // backlinksRoutes,
  // ReportsRoutes,
  todoRoutes,
  dataSourceRoutes,
  // CronJobsLogsRoutes,
  // projectsRoutes,
  // SettingsRoutes,
]
