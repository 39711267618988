import { api } from "../config"

import {
  post,
  get,
  //   patch,
  Delete,
  //   postFormData,
  //   postFileDelete,
  //   patchFileUpload,
} from "../_helpers/httpHandler"

const createTodoTasks = (payload) => post(`${api.localUrl}todo/create`, payload)

const getTodoTasks = (projectId, type) => get(`${api.localUrl}todo/getTodo?projectId=${projectId}&type=${type}`)

const deleteTodoTasks = (projectId) => Delete(`${api.localUrl}todo/deleteTodo?id=${projectId}`)

const getCompleted = (projectId) => get(`${api.localUrl}todo/getCompleted?projectId=${projectId}`)

const updateTodo = (rowId, completed) => get(`${api.localUrl}todo/todoUpdate?id=${rowId}&completed=${completed}`)

const getSummary = (projectId) => get(`${api.localUrl}todo/getSummary?projectId=${projectId}`)

//create notes
const createNotes = (payload) => post(`${api.localUrl}notes/create`, payload)

const getNotes = (user_id, proj_id) => get(`${api.localUrl}notes/getNotes?proj_id=${proj_id}&user_id=${user_id}`)

const getCurrentNote = (proj_id,user_id,url) => get(`${api.localUrl}notes/getCurrentNote?proj_id=${proj_id}&user_id=${user_id}&url=${url}`)

// 
//user management
const getAllUsers = (user_id) => get(`${api.localUrl}accounts/getAllUsers?user_id=${user_id}`)

const getUserSummary = () => get(`${api.localUrl}accounts/getUsersSummary`)

const deleteUser = (user_id) => Delete(`${api.localUrl}accounts/deleteUser?id=${user_id}`)

export const todotaskService = { getCurrentNote,deleteUser, getUserSummary, getAllUsers, getNotes, createNotes, getSummary, updateTodo, getCompleted, createTodoTasks, getTodoTasks, deleteTodoTasks }
