import React, { useState } from "react"
import styled, { createGlobalStyle } from "styled-components"
// import { styled } from "@mui/styles"
import Sidebar from "../components/Sidebar"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Settings from "../components/Settings"
import { spacing } from "@mui/system"
import { Hidden, CssBaseline, Paper as MuiPaper } from "@mui/material"
import { withWidth } from "@material-ui/core"
import { isWidthUp } from "@material-ui/core/withWidth"

const drawerWidth = 260

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const style = {
    height: "100vh",
    overflow: "auto",
    flexGrow: 1,
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </Drawer>
      <AppContent style={style}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>{children}</MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  )
}

export default withWidth()(Dashboard)
