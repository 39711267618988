import React, { useState } from "react"
import CreateIcon from "@mui/icons-material/Create"
import { useForm } from "react-hook-form"
import { todotaskService } from "../_services/todotasksServices"
import { useSnackbar } from "notistack"
import { Button, Drawer, TextField, Box } from "@mui/material"
import "./index.css"
import { useSelector } from "react-redux"
function Settings() {
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)
  // const [loader, setLoader] = useState(true)
  const [takenNotes, setTakenNotes] = useState(null)
  const location = window.location.href.split("/")
  const currentPath = window.location.pathname
  const { userReducer } = useSelector((state) => state)
  const { user } = userReducer

  const { handleSubmit, register, errors, formState } = useForm({
    mode: "onBlur",
  })
  const { isSubmitting } = formState
  const path = location[3]

  const proj_id = location[4]
  const user_id = user.id
  const url = currentPath

  const toggleDrawer = async () => {
    if (!isOpen) {
      getCurrentNotes()
    }
    setOpen(!isOpen)
  }
  const onSubmit = async (e) => {
    const payload = {
      text: e.note,
      url: url,
      user_id: user_id,
      proj_id: proj_id ? parseInt(proj_id) : null,
    }
    // console.log(payload)
    await todotaskService
      .createNotes(payload)
      .then((res) => {
        setTakenNotes(res.results)
        // getCurrentNotes(user_id,proj_id,url)
        enqueueSnackbar(res.message, {
          variant: "success",
        })
      })
      .catch((e) => {
        console.log(e)
        // enqueueSnackbar(e, {
        //   variant: "error",
        // })
      })
  }

  const getCurrentNotes = async () => {
    if (user_id && proj_id && url) {
      await todotaskService
        .getCurrentNote(proj_id, user_id, url)
        .then((res) => {
          // console.log(res)
          if (res && res.results) {
            setTakenNotes(res.results.text)
          }
        })
        .catch((e) => {
          enqueueSnackbar(e, {
            variant: "error",
          })
        })
    }
  }
  // useEffect(() => {
  //   getCurrentNotes(proj_id, user_id, url)
  // }, [])

  const changeValue = (e, value) => {}

  return (
    <React.Fragment>
      {/* {console.log(path)} */}
      {path === "" || path === "all-projects" ? null : (
        <Box className="drawerButton" onClick={toggleDrawer}>
          <CreateIcon />
        </Box>
      )}
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <div className="settings_drawer">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <TextField
              className="textarea_settings mt-2"
              variant="outlined"
              multiline
              rows={25}
              name="note"
              type="note"
              defaultValue={takenNotes ? takenNotes : ""}
              inputRef={register({
                required: "Required",
              })}
              label="Take your Note"
              margin="normal"
              error={!!errors.note}
              helperText={errors.note ? errors.note.message : ""}
              fullWidth
              onChange={(e) => changeValue(e, "details")}
            />
            <Box textAlign="right" justifyContent="space-between" className="req_title">
              <Button disabled={isSubmitting} type="submit" size="medium" variant="contained" color="primary" className="send_request">
                SAVE NOTE
              </Button>
            </Box>
          </form>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default Settings
