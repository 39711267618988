import { api } from "../config"

import {
  post,
  get,
  //   patch,
  Delete,
  postFormData,
  //   postFileDelete,
  //   patchFileUpload,
} from "../_helpers/httpHandler"

//create project
const createProject = (payload) => post(`${api.localUrl}project/create`, payload)

//edit project
const editProject = (payload) => post(`${api.localUrl}project/edit`, payload)

//delete Project
const deleteProjectById = (payload) => post(`${api.localUrl}project/delete`, payload)

//add site Map
const addSiteMap = (payload) => postFormData(`${api.localUrl}siteMap/addSiteMap`, payload)

//add product Feed
const addProductFeed = (payload) => postFormData(`${api.localUrl}products/addProductFeed`, payload)

const getAllProjects = () => get(`${api.localUrl}project/getAllProjects`)

//get project by id
const getProjectById = (id) => get(`${api.localUrl}project/getProjectById?projectId=${id}`)

const autoSuggestedSettingsByProjId = (id) => get(`${api.localUrl}project/autoSuggestedSettingsByProjId?projectId=${id}`)
// get lighhouse
const editSuggestedSettingsByProjId = (payload) => post(`${api.localUrl}project/editSuggestedSettingsByProjId`, payload)
// get lighhouse
const lightHouse = (payload) => post(`${api.localUrl}googleApis/lighthouse`, payload)

//get website erformance by project id
const getWebsitePerformance = (id) => get(`${api.localUrl}websitePerformances/getWebsitePerformanceByProjId?projectId=${id}`)

const googleAuthService = (id, type, frontendUrl) => get(`${api.localUrl}auth/getAuthUrl?projectId=${id}&type=${type}&frontendUrl=${frontendUrl}`)

const getUserDataSourceTokens = (id) => get(`${api.localUrl}dataSources/getUserDataSourceTokens?projectId=${id}`)

const getUserDataSourceViewsByProjectIds = (id) => get(`${api.localUrl}googleAnalytics/retrieveGoogleViewIds?projectId=${id}`)

const updateUserDataSourceViewsByProjectIds = (payload) => post(`${api.localUrl}googleAnalytics/updateGoogleViewIds`, payload)

const getUserDataSourceCustomersByProjectIds = (id) => get(`${api.localUrl}googleAdwords/retrieveGoogleAdIds?projectId=${id}`)

const updateAdWordsDataSourceViewsByProjectIds = (payload) => post(`${api.localUrl}googleAdwords/updateGoogleCustomers`, payload)

const getSiteMapByProjId = (id) => get(`${api.localUrl}siteMap/getSiteMapByProjId?projectId=${id}`)

const deleteDataSourceToken = (payload) => Delete(`${api.localUrl}dataSources/deleteDataSourceToken`, payload)

const getUserAccounts = () => get(`${api.localUrl}accounts`)

const analyticsForProject = (projectId) => get(`${api.localUrl}googleAnalytics/analyticsForProject?projectId=${projectId}`)

const getUserMails = (projectId, userId) => get(`${api.localUrl}accounts/getEmail?proj_id=${projectId}&user_id=${userId}`)

const createSharedProject = (payload) => post(`${api.localUrl}accounts/createShare`, payload)

const deleteSharedUser = (payload) => post(`${api.localUrl}accounts/deleteSharedUser`, payload)

const getGscPageUrls = (proj_id) => get(`${api.localUrl}pages/getAllPageUrlsFromGSC?projectId=${proj_id}`)

const getAllPagekeywords = (payload) => post(`${api.localUrl}pages/getKwsForPagesFeatured`, payload)

const featuredKwsAndPages = (payload) => post(`${api.localUrl}keywords/assignKwsToFeauturedPages`, payload)

const sharedProjects = (payload) => get(`${api.localUrl}project/sharedProjects?id=${payload}`)
const cronLogs = (payload) => get(`${api.localUrl}logs?userId=${payload}`)
// http://localhost:8080/api/pages/getKwsForPagesFeatured?projectId=558

export const globalServices = { cronLogs, sharedProjects, featuredKwsAndPages, getAllPagekeywords, getGscPageUrls, deleteSharedUser, createSharedProject, getUserMails, analyticsForProject, getUserAccounts, deleteDataSourceToken, editSuggestedSettingsByProjId, getSiteMapByProjId, updateAdWordsDataSourceViewsByProjectIds, getUserDataSourceCustomersByProjectIds, updateUserDataSourceViewsByProjectIds, getUserDataSourceViewsByProjectIds, getUserDataSourceTokens, googleAuthService, addSiteMap, addProductFeed, createProject, getWebsitePerformance, getProjectById, lightHouse, getAllProjects, editProject, deleteProjectById, autoSuggestedSettingsByProjId }
