import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE, USER_LOGOUT, USER_MEMBER_UPDATE } from "../actions/Types"
const INIT_STATE = {
  loading: false,
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  error: null,
  isLogged: false,
}
const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true }
    case USER_LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload))
      return { ...state, loading: false, user: action.payload, isLogged: true }
    case USER_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case USER_LOGOUT:
      return { ...INIT_STATE, user: null, isLogged: false }
    case USER_MEMBER_UPDATE:
      return { ...state, user: { ...state.user, type: action.payload } }
    default:
      return { ...state }
  }
}
export default userReducer
