import { api } from "../config"

import {
  post,
  get,
  put,
  //   patch,
  //   Delete,
  //   postFormData,
  //   postFileDelete,
  //   patchFileUpload,
} from "../_helpers/httpHandler"
// const url = api.localUrl

// get all pages
const getAllpages = (projectId) => post(`${api.localUrl}pages/getAllPages`, projectId)

// get all pages
const getFilteredPages = (projectId, action) => get(`${api.localUrl}pages/getFilteredPages?projectId=${projectId}&action=${action}`)

const getAllpagesDatesWise = (payload) => post(`${api.localUrl}pages/getAllPages`, payload)

// get all products
const getAllProducts = (id) => get(`${api.localUrl}products/getAllProducts?userId=${id}`)

// get light house data for single page
const getLightHouseDataForPage = (singlePageData) => post(`${api.localUrl}googleApis/lightHouseByPageUrl`, singlePageData)

const scanAgainLightHouse = (payload) => post(`${api.localUrl}googleApis/lightHouseByPageUrl`, payload)
// get key words
const getKeyWords = (payload) => post(`${api.localUrl}keywords/getKeywordsByUrl`, payload)

// get key words
const getKeyWordsForUrl = (payload) => post(`${api.localUrl}googleAdwords/getKeywordsForUrl`, payload)
// get single page data
const getSinglePageData = (id) => get(`${api.localUrl}pages/getPageById?pageId=${id}`)

// get Organised Keywords By Project
const getOrganisedKeywordsByProject = (id, pageId, type) => get(`${api.localUrl}keywords/getOrganisedKeywordsByProject?projectId=${id}&pageId=${pageId}&type=${type}`)

// get Keywords By Page
const getKeywordsByPage = (id) => get(`${api.localUrl}keywords/getKeywordsByPage?pageId=${id}`)

// post organise Keywords To Page
const organiseKeywordsToPage = (pageIds) => post(`${api.localUrl}keywords/organiseKeywordsToPage`, pageIds)

// get SearcConsole Keywords
const getSearcConsoleKeywords = (url, projectId) => get(`${api.localUrl}googleSearchConsole/retrieveSearchesByQuery?&url=${url}&projectId=${projectId}`)

const organiseKeywordsToProject = (payload) => post(`${api.localUrl}keywords/organiseKeywordsToProject`, payload)
// get Keywords For Url
const getKeywordsForUrl = (synoymous) => post(`${api.localUrl}googleAdwords/getKeywordsForUrl`, synoymous)

// page optimization
const urlOptimization = (optimizeData) => post(`${api.localUrl}pages/optimizePage`, optimizeData)

const getAutoAssignedSettingsByPageId = (id) => get(`${api.localUrl}pages/getAutoAssignedSettingsByPageId?pageId=${id}`)

const updateAutoAssignedSettingsByPageId = (payload) => put(`${api.localUrl}pages/updateAutoAssignedSettingsByPageId`, payload)

const allocateKeywordToProject = (payload) => post(`${api.localUrl}keywords/allocateKeywordToProject`, payload)
const indexPageByGoogle = (payload) => get(`${api.localUrl}pages/indexPageByGoogle?pageId=${payload}`)
const getPagesAutoSuggestedToKeyword = (keywordId) => get(`${api.localUrl}pages/autoSuggestedPagesByKeywordId?keywordId=${keywordId}`)

// get SearcConsole Keywords
const getSearchConsolePages = (projectId) => get(`${api.localUrl}googleSearchConsole/searchConsolePages?projectId=${projectId}`)

export const pagesServices = { getSearchConsolePages, getPagesAutoSuggestedToKeyword, getFilteredPages, indexPageByGoogle, getAllpagesDatesWise, allocateKeywordToProject, scanAgainLightHouse, getSearcConsoleKeywords, getKeywordsForUrl, organiseKeywordsToProject, getKeyWordsForUrl, urlOptimization, organiseKeywordsToPage, getAllpages, getKeywordsByPage, getAllProducts, getLightHouseDataForPage, getKeyWords, getSinglePageData, getOrganisedKeywordsByProject, getAutoAssignedSettingsByPageId, updateAutoAssignedSettingsByPageId }
