import React, { useState } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import AuthLayout from "../../layouts/Auth"
import { useForm } from "react-hook-form"
import { userService } from "../../_services/auth.service"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useSnackbar } from "notistack"
import logo from "../../assets/images/bd-logo.png"
import "./index.css"
import { Box, TextField, Button as MuiButton, Paper, Typography, IconButton, InputAdornment } from "@mui/material"
import { spacing } from "@mui/system"

const Button = styled(MuiButton)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function ResetPassword() {
  let history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "all",
  })
  const { isSubmitting } = formState
  const onSubmit = (data) => {
    let token = window.location.href.split("=")[1]
    let newPassword = { ...data, token: token }
    resetPassword(newPassword, history)
  }
  const changeValue = (e, type) => {
    const value = e.target.value
    const nextState = {}
    nextState[type] = value
  }
  async function resetPassword(newPassword, history) {
    await userService
      .ResetPassword(newPassword)
      .then((res) => {
        enqueueSnackbar(res.message, {
          variant: "success",
        })
        history.push("/auth/sign-in")
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: "error",
        })
      })
  }
  return (
    <AuthLayout>
      <Wrapper>
        <Box className="biscuit_logo">
          <img src={logo} alt="logo" width="180" />
        </Box>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your password to reset
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="row pl-2 pr-2">
            <div className="col-md-12 col-12 mb-2">
              <TextField
                variant="standard"
                fullWidth
                name="password"
                // type="password"
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                inputRef={register({
                  required: "Required",
                  pattern: {
                    value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                    message: "Invalid Password need at least a number, and at least a special character(Eg: @,$,!,%,*,?, &) and min 6 characters",
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
                margin="normal"
                className="mt-2"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                onChange={(e) => changeValue(e, "password")}
              />
            </div>
          </div>
          <div className="text-center pt-2">
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </Wrapper>
    </AuthLayout>
  )
}

export default ResetPassword
