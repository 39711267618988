import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import { Grid, Hidden, List, ListItemText, ListItem as MuiListItem } from "@mui/material"

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`

const ListItem = styled(MuiListItem)`
  display: inline-block !important;
  width: auto !important;
  padding-left: ${(props) => props.theme.spacing(2)}px!important;
  padding-right: ${(props) => props.theme.spacing(2)}px!important;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`

function Footer() {
  const location = window.location.href.split("/")
  const history = useHistory()
  const goToSupport = (e) => {
    history.push(`/project/${location[4]}/support`)
  }
  const goToHelpCenter = (e) => {
    history.push(`/project/${location[4]}/help-center`)
  }
  const goToPrivacy = (e) => {
    history.push(`/project/${location[4]}/privacy`)
  }
  const goToTermsOfService = (e) => {
    history.push(`/project/${location[4]}/termsofservice`)
  }
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid container item md={6}>
            <List>
              <ListItem className="navigation" onClick={goToSupport}>
                <ListItemText primary="Support" />
              </ListItem>
              <ListItem className="navigation" onClick={goToHelpCenter}>
                <ListItemText primary="Help Center" />
              </ListItem>
              <ListItem className="navigation" onClick={goToPrivacy}>
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem className="navigation" onClick={goToTermsOfService}>
                <ListItemText primary="Terms of Service" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item md={6} justifyContent="flex-end">
          <List>
            <ListItem>
              <ListItemText primary={`© ${new Date().getFullYear()} - Biscuit Data`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default Footer
