//PROJECTS
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST"
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE"
export const GET_PROJECTS_RESET = "GET_PROJECTS_RESET"

// LIGHTHOUSE
export const GET_LIGHTHOUSE_SUCCESS = "GET_LIGHTHOUSE_SUCCESS"
export const GET_LIGHTHOUSE_REQUEST = "GET_LIGHTHOUSE_REQUEST"
export const GET_LIGHTHOUSE_FAILURE = "GET_LIGHTHOUSE_FAILURE"

//SET DEFAULT PROJECT
export const SET_DEFAULT_PROJECT = "SET_DEFAULT_PROJECT"

//Pages
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS"
export const GET_PAGES_REQUEST = "GET_PAGES_REQUEST"
export const GET_PAGES_FAILURE = "GET_PAGES_FAILURE"
export const UPDATE_TRACKED_PAGES = "UPDATE_TRACKED_PAGES"
// dashboard
export const GET_MONTHLYREVENUE_SUCCESS = "GET_MONTHLYREVENUE_SUCCESS"
export const GET_MONTHLYREVENUE_REQUEST = "GET_MONTHLYREVENUE_REQUEST"
export const GET_MONTHLYREVENUE_FAILURE = "GET_MONTHLYREVENUE_FAILURE"

//dshboard keywords
export const GET_DASHBOARDKEYWORDS_SUCCESS = "GET_DASHBOARDKEYWORDS_SUCCESS"
export const GET_DASHBOARDKEYWORDS_REQUEST = "GET_DASHBOARDKEYWORDS_REQUEST"
export const GET_DASHBOARDKEYWORDS_FAILURE = "GET_DASHBOARDKEYWORDS_FAILURE"

// dashboard avarage page score
export const GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS = "GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS"
export const GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST = "GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST"
export const GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE = "GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE"

//dshboard lighthouse
export const GET_DASHBOARDLIGHTHOUSE_SUCCESS = "GET_DASHBOARDLIGHTHOUSE_SUCCESS"
export const GET_DASHBOARDLIGHTHOUSE_REQUEST = "GET_DASHBOARDLIGHTHOUSE_REQUEST"
export const GET_DASHBOARDLIGHTHOUSE_FAILURE = "GET_DASHBOARDLIGHTHOUSE_FAILURE"

//keywords for Keywords Analysis
export const GET_KEYWORD_ANALYSIS_SUCCESS = "GET_KEYWORD_ANALYSIS_SUCCESS"
export const GET_KEYWORD_ANALYSIS_REQUEST = "GET_KEYWORD_ANALYSIS_REQUEST"
export const GET_KEYWORD_ANALYSIS_FAILURE = "GET_KEYWORD_ANALYSIS_FAILURE"

//keywords overview
export const GET_KEYWORD_OVERVIEW_SUCCESS = "GET_KEYWORD_OVERVIEW_SUCCESS"
export const GET_KEYWORD_OVERVIEW_REQUEST = "GET_KEYWORD_OVERVIEW_REQUEST"
export const GET_KEYWORD_OVERVIEW_FAILURE = "GET_KEYWORD_OVERVIEW_FAILURE"

// visibility index
export const GET_VISIBILITY_INDEX_REQUEST = "GET_VISIBILITY_INDEX_REQUEST"
export const GET_VISIBILITY_INDEX_SUCCESS = "GET_VISIBILITY_INDEX_SUCCESS"
export const GET_VISIBILITY_INDEX_FAILURE = "GET_VISIBILITY_INDEX_FAILURE"

//Average position
export const GET_AVERAGE_POSITION_SUCCESS = "GET_AVERAGE_POSITION_SUCCESS"
export const GET_AVERAGE_POSITION_REQUEST = "GET_AVERAGE_POSITION_REQUEST"
export const GET_AVERAGE_POSITION_FAILURE = "GET_AVERAGE_POSITION_FAILURE"

//dashboard rank distribution
export const GET_DASHBOARD_RANK_DISTBU_REQUEST = "GET_DASHBOARD_RANK_DISTBU_REQUEST,"
export const GET_DASHBOARD_RANK_DISTBU_SUCCESS = "GET_DASHBOARD_RANK_DISTBU_SUCCESS,"
export const GET_DASHBOARD_RANK_DISTBU_FAILURE = "GET_DASHBOARD_RANK_DISTBU_FAILURE"

//dashboard page speed
export const GET_PAGE_SPEED_REQUEST = "GET_PAGE_SPEED_REQUEST"
export const GET_PAGE_SPEED_SUCCESS = "GET_PAGE_SPEED_SUCCESS"
export const GET_PAGE_SPEED_FAILURE = "GET_PAGE_SPEED_FAILURE"

//dashboard assigned pages
export const GET_ASSIGNED_PAGES_REQUEST = "GET_ASSIGNED_PAGES_REQUEST"
export const GET_ASSIGNED_PAGES_SUCCESS = "GET_ASSIGNED_PAGES_SUCCESS"
export const GET_ASSIGNED_PAGES_FAILURE = "GET_ASSIGNED_PAGES_FAILURE"

//dashboard actions
export const GET_DASHBOARD_ACTIONS_REQUEST = "GET_DASHBOARD_ACTIONS_REQUEST"
export const GET_DASHBOARD_ACTIONS_SUCCESS = "GET_DASHBOARD_ACTIONS_SUCCESS"
export const GET_DASHBOARD_ACTIONS_FAILURE = "GET_DASHBOARD_ACTIONS_FAILURE"

export const SET_DATA_SOURCE = "SET_DATA_SOURCE"
// GET_DASHBOARD_ACTIONS_FAILURE

export const IS_DASHBOARD_NEED_REFRESH = "IS_DASHBOARD_NEED_REFRESH"

//login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_MEMBER_UPDATE = "USER_MEMBER_UPDATE"
