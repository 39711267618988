import { GET_PAGES_REQUEST, GET_PAGES_FAILURE, GET_PAGES_SUCCESS } from "../actions/Types"

const INIT_STATE = {
  pages: [],
  loading: false,
  error: null,
}
const pagesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAGES_REQUEST:
      return { ...state, loading: true }
    case GET_PAGES_SUCCESS:
      return { ...state, loading: false, pages: [...action.payload] }
    case GET_PAGES_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return { ...state }
  }
}
export default pagesReducer
