import { api } from "../config"

import {
  post,
  get,
  //   patch,
  //   Delete,
  //   postFormData,
  //   postFileDelete,
  //   patchFileUpload,
} from "../_helpers/httpHandler"

const monthlyRevenue = (projectId) => get(`${api.localUrl}keywords/monthlyRevenue?projectId=${projectId}`)

const dashboardKeywords = ({ id, type }) => get(`${api.localUrl}dashboard?projectId=${id}&type=${type}`)

const dashboardLightHouse = (projectId) => get(`${api.localUrl}googleApis/lighthouseByProject?projectId=${projectId}`)

const averagePageScore = (projectId) => get(`${api.localUrl}dashboard/averagePageScore?projectId=${projectId}`)

const keywordsRankDistribution = (projectId) => get(`${api.localUrl}keywords/keywordsRankDistribution?projectId=${projectId}`)

const pageSpeedByPoject = (projectId) => get(`${api.localUrl}googleApis/pageSpeedByPoject?projectId=${projectId}`)

const getAssignedPages = (projectId) => get(`${api.localUrl}dashboard/assignedPages?projectId=${projectId}`)
const feedback = (data) => post(`${api.localUrl}dashboard/feedback`, data)

const getDashboardActions = (projectId) => get(`${api.localUrl}dashboard/dashboardActions?projectId=${projectId}`)

export const dashboardService = { feedback, getDashboardActions, getAssignedPages, pageSpeedByPoject, monthlyRevenue, dashboardKeywords, dashboardLightHouse, averagePageScore, keywordsRankDistribution }
