import { api } from "../config"
import { post } from "../_helpers/httpHandler"

const url = api.localUrl

const userLogin = (payload) => post(`${api.localUrl}auth/login`, payload)

async function signup(userData) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...userData }),
  }
  const response = await fetch(`${url}auth/register`, requestOptions)
  const user = await handleResponse(response)
  // store user details and jwt token in session storageto keep user logged in between page refreshes
  localStorage.setItem("user", JSON.stringify(user))
  return user
}

async function forgotPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }
  const response = await fetch(`${url}auth/forgotPassword`, requestOptions)
  const res = await handleResponse(response)
  return res
}

async function ResetPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }
  const response = await fetch(`${url}auth/resetPassword`, requestOptions)
  const res = await handleResponse(response)
  return res
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user")
      }
      // console.log(data.message)
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

export const userService = { userLogin, signup, forgotPassword, ResetPassword }
