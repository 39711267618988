import { GET_PAGES_SUCCESS, GET_PAGES_FAILURE, GET_PAGES_REQUEST, SET_DATA_SOURCE, GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAILURE, GET_LIGHTHOUSE_REQUEST, SET_DEFAULT_PROJECT, GET_LIGHTHOUSE_SUCCESS, GET_LIGHTHOUSE_FAILURE } from "./Types"
import { globalServices } from "../../_services/global.services"
import { pagesServices } from "../../_services/pages.services"

export const getAllProjects = () => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_PROJECTS_REQUEST, payload: [] })

  await globalServices.getAllProjects().then(
    (data) => {
      if (data.results.length > 0) {
        dispatch({ type: GET_PROJECTS_SUCCESS, payload: data.results })
      } else {
        dispatch({ type: GET_PROJECTS_FAILURE, payload: "No Projects" })
      }
    },
    (error) => {
      dispatch({ type: GET_PROJECTS_FAILURE, payload: error })
    }
  )
}

export const setDefaultProject = (userId, value) => async (dispatch) => {
  dispatch({ type: SET_DEFAULT_PROJECT, payload: value })
}

export const getAllpages = (userId, projectId) => async (dispatch) => {
  dispatch({ type: GET_PAGES_REQUEST, payload: [] })
  await pagesServices
    .getAllpages(userId, projectId)
    .then((res) => {
      dispatch({ type: GET_PAGES_SUCCESS, payload: res.results })
    })
    .catch((err) => {
      dispatch({ type: GET_PAGES_FAILURE, payload: err })
    })
}

export const getWebsitePerformance = (value) => (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_LIGHTHOUSE_REQUEST, payload: [] })

  globalServices.getWebsitePerformance(value).then(
    (data) => {
      dispatch({ type: GET_LIGHTHOUSE_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_LIGHTHOUSE_FAILURE, payload: error })
    }
  )
}

// dashboard actions
export const dataSourceAction = (source) => async (dispatch) => {
  dispatch({ type: SET_DATA_SOURCE, payload: source })
}
