import { GET_KEYWORD_ANALYSIS_SUCCESS, GET_KEYWORD_ANALYSIS_REQUEST, GET_KEYWORD_ANALYSIS_FAILURE, GET_KEYWORD_OVERVIEW_SUCCESS, GET_KEYWORD_OVERVIEW_REQUEST, GET_KEYWORD_OVERVIEW_FAILURE } from "../actions/Types"

const INIT_STATE = {
  keywordData: null,
  keywordsAnalysisloading: false,
  dataForaveragePosition: null,
  averagePositionLoading: false,
  keywordsOverviewData: null,
  keyworsOverviewLoading: false,
}

const keywordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_KEYWORD_ANALYSIS_REQUEST:
      return { ...state, keywordsAnalysisloading: true }
    case GET_KEYWORD_ANALYSIS_SUCCESS:
      return { ...state, keywordsAnalysisloading: false, keywordData: action.payload }
    case GET_KEYWORD_ANALYSIS_FAILURE:
      return { ...state, keywordsAnalysisloading: false, error: action.payload }

    // keywodrs Overview
    case GET_KEYWORD_OVERVIEW_REQUEST:
      return { ...state, keyworsOverviewLoading: true }
    case GET_KEYWORD_OVERVIEW_SUCCESS:
      return { ...state, keyworsOverviewLoading: false, keywordsOverviewData: action.payload }
    case GET_KEYWORD_OVERVIEW_FAILURE:
      return { ...state, keyworsOverviewLoading: false, error: action.payload }
    default:
      return { ...state }
  }
}
export default keywordReducer
