// here configuration for connecting backend

// export const api = "https://earnest-mark-285609.oa.r.appspot.com/";

// export const api =  "http://localhost:8080/api/"

export const envirnoment = {
  envName: "prod",
}

export const api = {
  localUrl: envirnoment.envName === "stag" ? "http://localhost:8080/api/" : "https://earnest-mark-285609.oa.r.appspot.com/api/",
}
