import React from "react"
import { connect } from "react-redux"
import { ThemeProvider as MuiThemeProvider } from "@mui/styles"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { Grow } from "@mui/material"
import { StylesProvider } from "@material-ui/styles"
import { ThemeProvider } from "styled-components"
import { SnackbarProvider } from "notistack"
import maTheme from "./theme"
import Routes from "./routes/Routes"

function App({ theme }) {
  return (
    <React.Fragment>
      <StylesProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <SnackbarProvider
                TransitionComponent={Grow}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                maxSnack={3}
                autoHideDuration={5000}
              >
                <Routes />
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </StylesProvider>
    </React.Fragment>
  )
}

export default connect((store) => ({ theme: store.themeReducer }))(App)
