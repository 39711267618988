import React, { Fragment } from "react"
import { Autocomplete, TextareaAutosize, TextField, Box, Button, Typography } from "@mui/material"

import { useFormik } from "formik"
import * as yup from "yup"
import { dashboardService } from "../_services/dashboardService"
import { useSnackbar } from "notistack"
import "./index.css"
import { useSelector } from "react-redux"
import DialogComponent from "../globalComponent/Dialog"

const validationSchema = yup.object({
  feedBackType: yup.string("Select Feedback").nullable().required("Feedback type is required"),
  message: yup.string("Enter message text").required("Message text is required"),
})

function FeedBack(props) {
  const { userReducer } = useSelector((state) => state)
  const { user } = userReducer
  const { enqueueSnackbar } = useSnackbar()
  const formik = useFormik({
    initialValues: {
      feedBackType: "",
      message: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dashboardService
        .feedback({ ...values, userId: user.id })
        .then((response) => {
          enqueueSnackbar(response.message, {
            variant: "success",
          })
        })
        .catch((err) => {
          enqueueSnackbar(err, {
            variant: "error",
          })
        })
      props.onClose()
    },
  })

  const feedBackOptions = ["Comments", "Suggestions", "Bug"]
  const { handleSubmit, values, touched, errors, handleChange, setFieldValue } = formik
  const FeedbackModelContent = () => {
    return (
      <>
        <Typography variant="h6"> Select a reason for submitting your help request:</Typography>
        <form onSubmit={handleSubmit} className="feedback_align">
          <Box className="feedback_options">
            <Autocomplete
              name="feedBackType"
              onChange={(e, value) => {
                setFieldValue("feedBackType", value)
              }}
              value={values.feedabackType}
              options={feedBackOptions}
              getOptionLabel={(option) => (option.length > 0 ? option : {})}
              className="feedback_text_field"
              renderInput={(params) => <TextField {...params} label="Feedback" variant="outlined" error={touched.feedBackType && Boolean(errors.feedBackType)} helperText={touched.feedBackType && errors.feedBackType} />}
            />
          </Box>
          <Box className="feedback_options">
            <Box className="feedback_options">
              <Typography variant="h6">Message</Typography>
            </Box>
            <Box className="feedback_options">
              <TextareaAutosize value={values.message} name="message" className={touched.message && errors.message ? "textarea_error" : "textarea_override"} error={touched.message && Boolean(errors.message)} helpertext={touched.message && errors.message} onChange={handleChange} placeholder="Message" minRows={10} label="Message" variant="outlined" />
              {touched.message && errors.message ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors.message}</p> : null}
            </Box>
            <Box textAlign="right" className="req_title">
              <Button size="medium" type="submit" variant="outlined" color="primary" className="send_request">
                SEND REQUEST
              </Button>
            </Box>
          </Box>
        </form>
      </>
    )
  }
  return (
    <Fragment>
      <DialogComponent openModel={props.open} maxWidth="lg" closeModel={props.onClose} title={"Support Requests"} Content={FeedbackModelContent} />
    </Fragment>
  )
}
export default FeedBack
