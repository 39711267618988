import React, { useState } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import AuthLayout from "../../layouts/Auth"
import { useForm } from "react-hook-form"
import { userService } from "../../_services/auth.service"
import { useSnackbar } from "notistack"
import logo from "../../assets/images/bd-logo.png"
import { Box, TextField, Button as MuiButton, Paper, Typography } from "@mui/material"
import { spacing } from "@mui/system"
import "./index.css"

const Button = styled(MuiButton)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function ForgotPassword() {
  let history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [isDisble, setDisable] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: "all",
  })
  // const { isSubmitting } = formState;
  const onSubmit = (data) => {
    setDisable(true)
    requestPassword(data, history)
  }

  function requestPassword(data, history) {
    userService
      .forgotPassword(data)
      .then((res) => {
        enqueueSnackbar(res.message, {
          variant: "success",
        })
        setDisable(false)
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: "error",
        })
        setDisable(false)
      })
  }
  return (
    <AuthLayout>
      <Wrapper>
        <Box className="biscuit_logo">
          <img src={logo} alt="logo" width="180" />
        </Box>
        <Typography component="h3" align="center" gutterBottom>
          Forgot Password
        </Typography>
        <Box textAlign={"center"}>Enter your email to reset your password</Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="row pl-2 pr-2">
            <div className="col-md-12 col-12 mb-2">
              <TextField
                variant="standard"
                disabled={isDisble}
                name="email"
                type="email"
                inputRef={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                label="Email"
                margin="normal"
                className="mt-2"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                fullWidth
              />
            </div>
          </div>
          <div className="text-center pt-2">
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={isDisble}>
              SEND RESET LINK
            </Button>
          </div>
        </form>
      </Wrapper>
    </AuthLayout>
  )
}

export default ForgotPassword
