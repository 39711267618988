import React, { useEffect } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { dashboardLayoutRoutes } from "./index"

import DashboardLayout from "../layouts/Dashboard"
// import AuthLayout from "../layouts/Auth"
// import Page404 from "../pages/auth/Page404"
import SignIn from "../pages/auth/SignIn"
import SignUp from "../pages/auth/SignUp"
import ForgotPassword from "../pages/auth/ForgotPassword"
import ResetPassword from "../pages/auth/ResetPassword"
import PrivateRoute from "./PrivateRoute"
const childRoutes = () =>
  dashboardLayoutRoutes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) =>
            path === "/adwords/success" ? (
              <Component {...props} />
            ) : (
              <DashboardLayout>
                <Component {...props} />
              </DashboardLayout>
            )
          }
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        )}
      />
    )
  )

const Routes = () => {
  useEffect(() => {
    return () => {
      // console.log("clean up")
    }
  }, [])
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth/sign-in" component={SignIn} />
        <Route path="/auth/sign-Up" component={SignUp} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        {/* <Route path="/email-sent" component={EmailSent} /> */}
        <PrivateRoute path="/" component={childRoutes} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
