import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { connect, useDispatch } from "react-redux"
import AddIcon from "@material-ui/icons/Add"
import { getAllProjects, getWebsitePerformance, setDefaultProject } from "../redux/actions/ProjectActions"
import { userLogout } from "../redux/actions/userActions"
import { isDashboardNeedsRefresh } from "../redux/actions/dashboardActions"

import { useSelector } from "react-redux"
import { Autocomplete, Tooltip, Fab, TextField, Box, Grid, Hidden, Menu, MenuItem, AppBar, IconButton as MuiIconButton, Toolbar, Button, Avatar, Divider } from "@mui/material"
import FeedBack from "./FeedBack"
import { Menu as MenuIcon } from "@material-ui/icons"
import { makeStyles } from "@mui/styles"

import "./index.css"

// const AppBar = styled(MuiAppBar)`
//   background: ${(props) => props.theme.header.background};
//   color: ${(props) => props.theme.header.color};
//   box-shadow: ${(props) => props.theme.shadows[1]};
// `

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const useStyles = makeStyles({
  option: {
    "&:hover": {
      backgroundColor: "#c5c5c5",
    },
  },
})

function Header(props) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { projects, loading, defaultProject, error } = props
  const { dashBoardReducer, userReducer } = useSelector((state) => state)
  const { dashboardkeywordsLoading } = dashBoardReducer
  const [dp, setDp] = useState(null)
  const [feedBack, setFeedBack] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isUserOwner, setIsUserOwner] = useState(false)
  const open = Boolean(anchorEl)
  const { user } = userReducer
  const projId = window.location.href.split("/")
  const firstLetter = user?.firstname.charAt()
  const secondLetter = user.lastname ? user.lastname.charAt() : ""
  const profileName = firstLetter + secondLetter
  useEffect(() => {
    const { type } = user
    if (type === "owner") {
      setIsUserOwner(true)
    } else {
      setIsUserOwner(false)
    }
  }, [user])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (projects.length === 0 && !error) {
      props.getAllProjects()
    }
  }, [error, projects])

  useEffect(() => {
    if (defaultProject) {
      setDp(defaultProject)
    } else {
      setDp(null)
    }
  }, [defaultProject])

  const openFeedBack = () => {
    setFeedBack(!feedBack)
  }
  const getProjectData = (e, value) => {
    if (value) {
      if (value.serp_scan_finished === false) {
        alert("The project is being initialised, please wait...")
      } else {
        setDp(value)
        dispatch(isDashboardNeedsRefresh(true))
        dispatch(setDefaultProject(null, value))

        if (window.location.href !== "/") {
          history.push(`/project/${value.id}/dashboard`)
        }
      }
      //make all api calls for dashboard to get update
    } else {
      return
    }
  }
  const createProject = (e) => {
    history.push("/create-project")
  }
  const userRoles = () => {
    history.push(`/members`)
  }
  const logout = () => {
    dispatch(userLogout())
  }

  return (
    <React.Fragment>
      {feedBack && <FeedBack onClose={openFeedBack} open={feedBack} />}
      <AppBar position="sticky" elevation={1} className="appbar">
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton color="inherit" aria-label="Open drawer" onClick={props.onDrawerToggle}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Box alignItems="center" display="flex">
                <Box margin={"10px"}>Project : </Box>
                <Box className="header_input">
                  {loading ? (
                    "Loading..."
                  ) : (
                    <Autocomplete
                      classes={{
                        option: classes.option,
                      }}
                      disabled={dashboardkeywordsLoading}
                      options={projects}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      value={dp ? dp : null}
                      onChange={getProjectData}
                      renderInput={(params) => <TextField {...params} variant="standard" placeholder="Search project" margin="normal" fullWidth />}
                      isOptionEqualToValue={(option, value) => value.id === option.id}
                      className="headerAutoSel"
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
                <Box>
                  {/* <Tooltip title="feedback" > */}
                  <Button size="medium" onClick={() => openFeedBack()} variant="outlined" disabled={feedBack}>
                    Feedback
                  </Button>
                  {/* </Tooltip> */}
                </Box>
                <Box>
                  <Tooltip title="Add Project">
                    <Fab onClick={createProject} color="primary" className="addProject" aria-label="add">
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Box>
                <Box>
                  <Fab color="primary" className="addProject" aria-label="add">
                    {/* <Avatar className="company_name" alt="" src={profileName}  /> */}
                    <Avatar className="company_name" alt="" onClick={handleClick}>
                      <span className="userTitles">{profileName}</span>
                    </Avatar>
                    <Menu className="header_menu" id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                      <Box textAlign="center" display="flex" alignItems="center" justifyContent="center"></Box>
                      <Box pt={3}>
                        <MenuItem className="menu_item_curser">
                          <span className="userName">{user ? user.firstname + " " + (user.lastname !== null ? user.lastname : "") : null}</span>
                        </MenuItem>
                      </Box>
                      <Box>
                        <Divider />
                        {projId[4] && (
                          <>
                            <MenuItem className="menu_item_curser">
                              <span className="userName">{dp ? dp.name : null}</span>
                            </MenuItem>
                            <Divider />
                          </>
                        )}
                        <MenuItem className="menu_item_curser">
                          <span className="user_menu_fonts">{user.email}</span>
                        </MenuItem>
                        {isUserOwner && (
                          <MenuItem className="user_menu_fonts" onClick={userRoles}>
                            Manage Accounts
                          </MenuItem>
                        )}
                      </Box>
                      <MenuItem className="user_menu_fonts" onClick={logout}>
                        Sign out
                      </MenuItem>
                    </Menu>
                  </Fab>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { projects, loading, defaultProject, error } = state.projectReducer

  return {
    projects: projects,
    loading: loading,
    defaultProject: defaultProject,
    error: error,
  }
}
// binds on `props` change
const mapDispatchToProps = (dispatch) => ({
  setDefaultProject: (userId, data) => dispatch(setDefaultProject(userId, data)),
  getAllProjects: (id) => dispatch(getAllProjects(id)),
  getWebsitePerformance: (id) => dispatch(getWebsitePerformance(id)),
  userLogout: () => dispatch(userLogout()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header)
