import { GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAILURE, SET_DEFAULT_PROJECT, GET_PROJECTS_RESET, SET_DATA_SOURCE } from "../actions/Types"
const INIT_STATE = {
  projects: [],
  loading: false,
  loadingDefault: false,
  error: null,
  defaultProject: localStorage.getItem("defaultProject") ? JSON.parse(localStorage.getItem("defaultProject")) : null,
  dataSource: null,
}

const projectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST:
      return { ...state, loading: true }

    case GET_PROJECTS_SUCCESS:
      return { ...state, loading: false, projects: action.payload }

    case GET_PROJECTS_FAILURE:
      return { ...state, loading: false, error: action.payload, defaultProject: null, projects: [] }
    case GET_PROJECTS_RESET:
      return { projects: [], loading: false, defaultProject: null, loadingDefault: false }
    case SET_DATA_SOURCE:
      return { ...state, dataSource: action.payload }
    case SET_DEFAULT_PROJECT:
      localStorage.setItem("defaultProject", action.payload ? JSON.stringify(action.payload) : action.payload)
      return { ...state, defaultProject: { ...action.payload } }

    default:
      return { ...state }
  }
}
export default projectReducer
