import { GET_DASHBOARD_ACTIONS_FAILURE, GET_DASHBOARD_ACTIONS_SUCCESS, GET_DASHBOARD_ACTIONS_REQUEST, GET_MONTHLYREVENUE_SUCCESS, GET_MONTHLYREVENUE_REQUEST, GET_MONTHLYREVENUE_FAILURE, GET_DASHBOARDKEYWORDS_SUCCESS, GET_DASHBOARDKEYWORDS_REQUEST, GET_DASHBOARDKEYWORDS_FAILURE, GET_DASHBOARDLIGHTHOUSE_SUCCESS, GET_DASHBOARDLIGHTHOUSE_REQUEST, GET_DASHBOARDLIGHTHOUSE_FAILURE, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST, GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE, GET_DASHBOARD_RANK_DISTBU_REQUEST, GET_DASHBOARD_RANK_DISTBU_SUCCESS, GET_DASHBOARD_RANK_DISTBU_FAILURE, GET_PAGE_SPEED_REQUEST, GET_PAGE_SPEED_SUCCESS, GET_PAGE_SPEED_FAILURE, GET_ASSIGNED_PAGES_REQUEST, GET_ASSIGNED_PAGES_SUCCESS, GET_ASSIGNED_PAGES_FAILURE, IS_DASHBOARD_NEED_REFRESH, UPDATE_TRACKED_PAGES } from "./Types"

import { dashboardService } from "../../_services/dashboardService"

//page speed
export const pageSpeedByPoject = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_PAGE_SPEED_REQUEST, payload: [] })

  await dashboardService.pageSpeedByPoject(id).then(
    (data) => {
      dispatch({ type: GET_PAGE_SPEED_SUCCESS, payload: data.results })
    },
    (error) => {
      dispatch({ type: GET_PAGE_SPEED_FAILURE, payload: error })
    }
  )
}

//monthly revenu api
export const monthlyRevenueAPI = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_MONTHLYREVENUE_REQUEST, payload: [] })

  await dashboardService.monthlyRevenue(id).then(
    (data) => {
      dispatch({ type: GET_MONTHLYREVENUE_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_MONTHLYREVENUE_FAILURE, payload: error })
    }
  )
}
//dashboard top 6 cards api
export const dashBoardKeywords =
  ({ id, type }) =>
  async (dispatch) => {
    // return dispatch => {
    dispatch({ type: GET_DASHBOARDKEYWORDS_REQUEST, payload: [] })

    await dashboardService.dashboardKeywords({ id, type }).then(
      (data) => {
        dispatch({ type: GET_DASHBOARDKEYWORDS_SUCCESS, payload: data.results })
      },
      (error) => {
        dispatch({ type: GET_DASHBOARDKEYWORDS_FAILURE, payload: error })
      }
    )
  }

//dashboard avvarage page score averagePageScore
export const averagePageScore = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_REQUEST, payload: [] })
  await dashboardService.averagePageScore(id).then(
    (data) => {
      // console.log(data)
      dispatch({ type: GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_DASHBOARDLAVARAGE_PAGE_PAGESCORE_FAILURE, payload: error })
    }
  )
}

//dashboard lighthouse api
export const dashboardLightHouse = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_DASHBOARDLIGHTHOUSE_REQUEST, payload: [] })

  await dashboardService.dashboardLightHouse(id).then(
    (data) => {
      dispatch({ type: GET_DASHBOARDLIGHTHOUSE_SUCCESS, payload: data.results })
    },
    (error) => {
      dispatch({ type: GET_DASHBOARDLIGHTHOUSE_FAILURE, payload: error })
    }
  )
}

//dashbaord keywords rank distribution
export const keywordsRankDistribution = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_DASHBOARD_RANK_DISTBU_REQUEST, payload: [] })

  await dashboardService.keywordsRankDistribution(id).then(
    (data) => {
      dispatch({ type: GET_DASHBOARD_RANK_DISTBU_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_DASHBOARD_RANK_DISTBU_FAILURE, payload: error })
    }
  )
}

// dsahboard assigned pages
export const assignedPages = (id) => async (dispatch) => {
  // return dispatch => {
  dispatch({ type: GET_ASSIGNED_PAGES_REQUEST, payload: [] })

  await dashboardService.getAssignedPages(id).then(
    (data) => {
      dispatch({ type: GET_ASSIGNED_PAGES_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_ASSIGNED_PAGES_FAILURE, payload: error })
    }
  )
}

// dashboard actions
export const dashboardActions = (id) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_ACTIONS_REQUEST, payload: [] })

  await dashboardService.getDashboardActions(id).then(
    (data) => {
      dispatch({ type: GET_DASHBOARD_ACTIONS_SUCCESS, payload: data })
    },
    (error) => {
      dispatch({ type: GET_DASHBOARD_ACTIONS_FAILURE, payload: error })
    }
  )
}

export const isDashboardNeedsRefresh = (value) => async (dispatch) => {
  dispatch({ type: IS_DASHBOARD_NEED_REFRESH, payload: value })
}
export const updateTrackedPages = (value) => async (dispatch) => {
  dispatch({ type: UPDATE_TRACKED_PAGES, payload: value })
}
