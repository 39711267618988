import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"

function PrivateRoute({ component: Component, ...rest }) {
  const { userReducer } = useSelector((state) => state)
  const { user } = userReducer
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("user")) {
          return <Redirect to={{ pathname: "/auth/sign-in", state: { from: props.location } }} />
        }
        if (props.location.pathname === "/connect-adwords" && user.id !== 57) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
