import { combineReducers } from "redux"

import themeReducer from "./themeReducers"
import projectReducer from "./projectReducer"
import pagesReducer from "./pagesReducer"
import dashboardReducer from "./dashboardReducer"
import keywordsReducer from "./keywordReducer"
import userReducer from "./userReducer"
export default combineReducers({
  themeReducer,
  projectReducer: projectReducer,
  pagesReducer: pagesReducer,
  dashBoardReducer: dashboardReducer,
  keywordsReducer: keywordsReducer,
  userReducer: userReducer,
})
